import { defineComponent as _defineComponent } from 'vue'
import { createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, withModifiers as _withModifiers, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"
import _imports_0 from '@/assets/image/timetable/prsnmt.png'
import _imports_1 from '@/assets/image/timetable/aimt.png'
import _imports_2 from '@/assets/image/timetable/ai.png'


const _hoisted_1 = { class: "material-types" }
const _hoisted_2 = { class: "material-types_imgs" }

import {} from 'vue'
import CloseIcon from "@/components/system/CloseIcon.vue";
import TimetableTitle from "@/components/timetable/TimetableTitle.vue";
import {useRoute, useRouter} from "vue-router";


export default /*@__PURE__*/_defineComponent({
  __name: 'MaterialTypes',
  setup(__props) {

let router = useRouter()
let route = useRoute()
const onClickType = (name: string): void => {
  router.replace({
    name: name,
    query: route.query
  })
}


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", null, [
      _createVNode(TimetableTitle, null, {
        default: _withCtx(() => _cache[6] || (_cache[6] = [
          _createTextVNode("自定义素材")
        ])),
        _: 1
      }),
      _createVNode(CloseIcon)
    ]),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("img", {
        src: _imports_0,
        alt: "",
        draggable: "false",
        onClick: _cache[0] || (_cache[0] = ($event: any) => (onClickType('SourceMaterial'))),
        onContextmenu: _cache[1] || (_cache[1] = _withModifiers(() => {}, ["prevent"]))
      }, null, 32),
      _createElementVNode("img", {
        src: _imports_1,
        alt: "",
        draggable: "false",
        onClick: _cache[2] || (_cache[2] = ($event: any) => (onClickType('MaterialAIMt'))),
        onContextmenu: _cache[3] || (_cache[3] = _withModifiers(() => {}, ["prevent"]))
      }, null, 32),
      _createElementVNode("img", {
        src: _imports_2,
        alt: "",
        draggable: "false",
        onClick: _cache[4] || (_cache[4] = ($event: any) => (onClickType('MaterialAIs'))),
        onContextmenu: _cache[5] || (_cache[5] = _withModifiers(() => {}, ["prevent"]))
      }, null, 32)
    ])
  ]))
}
}

})